import { useClipboard, usePermission } from '@vueuse/core';
import type { MaybeRefOrGetter } from 'vue';
import type { AlertArguments } from '~/types';

export function useCopyPaste (source?: MaybeRefOrGetter<string>) {
  const clipboardWriteAccess = usePermission('clipboard-write');
  const clipboardReadAccess = usePermission('clipboard-read');

  const { text, copy, copied, isSupported } = useClipboard({ source });
  const { add } = useToast();
  const { t } = useI18n();

  // using usePermission with firefox we get back "prompt"
  const canCopy = computed(() => clipboardWriteAccess.value === 'granted' || clipboardWriteAccess.value === 'prompt');
  const canRead = computed(() => clipboardReadAccess.value === 'granted' || clipboardWriteAccess.value === 'prompt');

  async function copyToClipboard (directSource?: string, toastOptions: Partial<AlertArguments> = {}) {
    if (clipboardGuard()) {
      const _source = toValue(source);
      try {
        await copy(directSource || _source);
        add({
          title: t('generics.copy-to-clipboard.success'),
          color: 'blue',
          icon: 'i-heroicons-information-circle',
          closeButton: {
            icon: ''
          },
          ...toastOptions
        });
        return true;
      } catch (error) {
        add({
          title: t('generics.copy-to-clipboard.error.title'),
          description: t('generics.copy-to-clipboard.error.description'),
          color: 'red',
          icon: 'i-heroicons-information-circle',
          closeButton: {
            icon: ''
          }
        });
        return false;
      }
    }
  }

  /**
   * Read the clipboard current content
   * Usefully if we want to access a copied value that wasn't copied by/within the our app.
   * Otherwise, privilege the 'text' variable.
   */
  async function readClipboard ():Promise<string | void> {
    if (clipboardGuard(false)) {
      return await navigator.clipboard.readText();
    }
  }

  function clipboardGuard (copy = true) {
    if (!isSupported.value) {
      add({
        title: t('generics.clipboard.not-supported.title'),
        description: t('generics.read-clipboard.not-supported.description'),
        color: 'yellow'
      });
      return false;
    }

    if (copy) {
      if (!canCopy.value) {
        add({
          title: t('generics.clipboard.permission-denied.title'),
          description: t('generics.copy-to-clipboard.permission-denied.description'),
          color: 'yellow',
          icon: 'i-heroicons-information-circle',
          closeButton: {
            icon: ''
          }
        });
        return false;
      }
      return true;
    }

    if (!canRead.value) {
      add({
        title: t('generics.clipboard.permission-denied.title'),
        description: t('generics.read-clipboard.permission-denied.description'),
        color: 'yellow',
        icon: 'i-heroicons-information-circle',
        closeButton: {
          icon: ''
        }
      });
      return false;
    }

    return true;
  }

  return {
    text,
    isSupported,
    copyToClipboard,
    isCopied: copied,
    readClipboard,
    canCopy,
    canRead
  };
}
